import React from 'react'
import Layout from "../../components/layout"
import { Link } from "gatsby"
import SEO from "../../components/seo" 
import resCuisine from "../../assets/img/realisations/residentiel/cuisine.jpg"
import resSalleDeBain from "../../assets/img/realisations/residentiel/salle-de-bain.jpg"

const RResidentielPage = () => (
      <Layout>
        <SEO title="Agencement" />
        <div class="w-full relative">
            <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
                <h1 class="text-center text-2xl mt-5 font-bold">DÉCOUVREZ NOS RÉALISATIONS EN RÉSIDENTIEL</h1>
                <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
                <p class="font-bold text-center my-5">AMÉNAGEMENT DE CUISINES ET SALLES DE BAIN EN SOLID SURFACE</p>
            </div> 
        </div>  
      <div class="grid grid-cols-1 md:grid-cols-2 md:gap-7 mt-8 md:mx-40 md:my-5 mx-3"> 
        <div class="hover:opacity-80">
         <Link to={`/realisations/residentiel-cuisine`}>
            <div class="relative"><img src={resCuisine} alt="résidentiel cuisine" />
              <p class="absolute top-2/3 font-bold right-0 bg-gray-700 text-white py-2 px-4">CUISINE</p>
            </div>
         </Link>
        </div>
        <div class="hover:opacity-80">
          <Link to={`/applications/salle-de-bain`}>
            <div class="relative"><img src={resSalleDeBain} alt="résidentiel salle de bain" />
              <p class="absolute top-2/3 font-bold right-0 bg-gray-700 text-white py-2 px-4">SALLE DE BAIN</p>
            </div>
          </Link>
        </div>
      </div>  
      </Layout> 
 )
export default RResidentielPage